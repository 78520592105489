import { action, observable } from "mobx";
import { ApiService, ApiUrl, Constants } from "helpers";
import Alert from 'react-s-alert';
import { campaignStore } from "stores";
import { FilterModelPageName, HeaderTabName } from "helpers/Constants";
import { FormatDate } from "helpers";

//TODO: refactor to use mobx correctly, actions, computed etc , not all one big observable
const emailStore = observable(
  {
    isFormDirty: false,
    id: undefined,
    isUpdatedCampaignName: false,
    campaignName: "",
    previousCampaignName: "",
    description: "",
    jsonTemplate: undefined,
    htmlTemplate: undefined,
    emailTemplatesList: [],
    emailsList: [],
    filteredEmailsList: [],
    filteredEmailTemplatesList: [],
    beeEditor: undefined,
    tempTemplateJsonData: undefined,
    tempTemplateHtmlData: undefined,
    isDisabledSaveButton: true, // default value
    isUseEmailTemplate: false,
    isEditTemplate: false,
    activeTab: null,
    tagCategories: [],
    isUpdateCampaignName: false,
    fromAutomation: false,
    isSendTestEmail: false,
    isSaveToTemplate: false,
    activeGrid: HeaderTabName.TAB_EMAIL_GRID_TEMPLATE,
    forceUpdate: false,
    isCopyToExisting: false,
    sourceId: undefined,
    isFromCopyToExisting: false,
    sourceTid: undefined,
    EmailFilters: {},
    emailTemplatesFilters: {},
    filterSearchText: "",
    originalCampaignName: "",

    setEmailFilters(data) {
      this.EmailFilters = data;
      sessionStorage.setItem(FilterModelPageName.EmailsPage, JSON.stringify(data));
    },
    setEmailTemplatesFilters(data) {
      this.emailTemplatesFilters = data;
      sessionStorage.setItem(FilterModelPageName.EmailTemplatesPage, JSON.stringify(data));
    },
    setForceUpdate(forceUpdate) { this.forceUpdate = forceUpdate; },
    setIsUpdatedCampaignName(isUpdatedCampaignName) { this.isUpdatedCampaignName = isUpdatedCampaignName; },
    setIsSaveToTemplate(isSaveToTemplate) { this.isSaveToTemplate = isSaveToTemplate; },
    setFromAutomation(fromAutomation) { this.fromAutomation = fromAutomation; },
    setIsSendTestEmail(isSendTestEmail) { this.isSendTestEmail = isSendTestEmail; },
    setIsUpdateCampaignName(isUpdateCampaignName) { this.isUpdateCampaignName = isUpdateCampaignName; },
    setIsUseEmailTemplate(isUseEmailTemplate) { this.isUseEmailTemplate = isUseEmailTemplate; },
    setIsEditTemplate(isEditTemplate) { this.isEditTemplate = isEditTemplate; },
    setIsDisabledEditorSaveButton(isDisabledSaveButton) { this.isDisabledSaveButton = isDisabledSaveButton; },
    setTempTemplateJsonData(jsonData) { this.tempTemplateJsonData = jsonData; },
    setTempTemplateHtmlData(htmlData) { this.tempTemplateHtmlData = htmlData; },
    setOriginalCampaignName(name) {
      this.originalCampaignName = name;
    },

    setEmailsSearchString(searchString) {
      const { FormatDateUtc } = FormatDate;

      const ApplySearchStringIfUpdatedDateExists = (item) => !!item?.updatedAt && FormatDateUtc(item?.updatedAt).substring(0, 11).toLowerCase().includes(searchString.toLowerCase()) ? true : false;

      this.filterSearchText = searchString;

      this.filteredEmailsList = this.emailsList.filter(item =>
        ApplySearchStringIfUpdatedDateExists(item) ||
        item.name.toLowerCase().includes(searchString.toLowerCase()) ||
        item.category?.toLowerCase().includes(searchString.toLowerCase()) ||
        item.updatedBy?.toLowerCase().includes(searchString.toLowerCase())
      );
    },

    setEmailTemplatesSearchString(searchString) {
      const { FormatDateUtc } = FormatDate;

      const ApplySearchStringIfUpdatedDateExists = (item) => !!item?.updatedAt && FormatDateUtc(item?.updatedAt).substring(0, 11).toLowerCase().includes(searchString.toLowerCase()) ? true : false;

      this.filterSearchText = searchString;

      this.filteredEmailTemplatesList = this.emailTemplatesList.filter(item =>
        ApplySearchStringIfUpdatedDateExists(item) ||
        item.description?.toLowerCase().includes(searchString.toLowerCase()) ||
        item.name.toLowerCase().includes(searchString.toLowerCase()) ||
        item.category?.toLowerCase().includes(searchString.toLowerCase()) ||
        item.updatedBy?.toLowerCase().includes(searchString.toLowerCase())
      );
    },

    clearTemplateData() {
      this.tempTemplateHtmlData = undefined;
      this.tempTemplateJsonData = undefined;
    },

    clearFormData() {
      this.id = undefined
      this.isUpdatedCampaignName = false;
      this.campaignName = "";
      this.previousCampaignName = "";
      this.description = "";
      this.jsonTemplate = undefined;
      this.htmlTemplate = undefined;
      this.isFormDirty = false;
      this.isDisabledSaveButton = true;
      this.isUseEmailTemplate = false;
      this.isEditTemplate = false;
      this.tagCategories = [];
      this.activeTab = null;
      this.isUpdateCampaignName = false;
      this.fromAutomation = false;
      this.isSaveToTemplate = false;
      this.forceUpdate = false;
      this.originalCampaignName = "";
    },

    setFormData(data) {
      this.id = data.id;
      this.campaignName = data.name ? data.name : data.campaignName;
      this.originalCampaignName = this.campaignName;
      this.previousCampaignName = this.campaignName;
      this.description = data.description;
      this.jsonTemplate = data.jsonTemplate;
      this.htmlTemplate = data.htmlTemplate;
      if (data.tagCategories !== undefined) {
        this.setTagCategories(JSON.parse(data.tagCategories).map((item) => {
          return {
            label: item,
            value: item
          }
        }), false);
      }
      this.isFormDirty = false;
      this.isUpdateCampaignName = false;
      this.isUpdatedCampaignName = false;
    },

    setIsFormDirty(isFormDirty) {
      this.isFormDirty = isFormDirty;
    },

    setId(id) {
      this.id = id;
    },

    setCampaignName(campaignName) {
      this.campaignName = campaignName;
      this.setIsUpdateCampaignName(true);
      this.setIsFormDirty(true);
    },

    setPreviousCampaignName(previousCampaignName) {
      this.previousCampaignName = previousCampaignName;
    },

    setTagCategories(tagCategories, setDirty = true) {
      this.tagCategories = [];
      this.tagCategories = [
        ...tagCategories
      ];
      if (setDirty) {
        this.setIsFormDirty(true);
      }
    },

    setDescription(description) {
      this.description = description;
      this.setIsFormDirty(true);
    },

    setJsonTemplate(jsonTemplate) {
      this.jsonTemplate = jsonTemplate;
    },

    setHtmlTemplate(htmlTemplate) {
      this.htmlTemplate = htmlTemplate;
    },

    setEmailTemplateList(emailTemplateList) {
      this.emailTemplatesList = emailTemplateList;
      this.filteredEmailTemplatesList = emailTemplateList;
    },

    setEmailExistingList(emailExistingList) {
      this.emailsList = emailExistingList;
      this.filteredEmailsList = emailExistingList;
      this.EmailFilters = {};
    },

    setBeeEditor(beeEditor) {
      this.beeEditor = beeEditor;
    },

    setActiveTab(activeTab) {
      this.activeTab = activeTab;
    },

    setActiveGrid(activeGrid) {
      this.activeGrid = activeGrid;
    },

    setIsCopyToExisting(isCopyToExisting) {
      this.isCopyToExisting = isCopyToExisting;
    },

    setSourceId(sourceId) {
      this.sourceId = sourceId;
    },

    setIsFromCopyToExisting(isFromCopyToExisting) {
      this.isFromCopyToExisting = isFromCopyToExisting;
    },

    setSourceTid(sourceTid) {
      this.sourceTid = sourceTid;
    },

    getEmailTemplates() {
      return (ApiService.get(ApiUrl.getListEmailTemplate, "", Constants.ALERT_FETCH_FAILED).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setEmailTemplateList(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          error => {
            return Promise.reject(error);
          }
        )
      ));
    },

    getEmails() {
      return (ApiService.get(ApiUrl.getListEmailCampaign, "", Constants.ALERT_FETCH_FAILED).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setEmailExistingList(response.data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          error => {
            return Promise.reject(error);
          }
        )
      ));
    },

    getEmailTemplateDetail() {
      return (ApiService.getDetail(ApiUrl.getEmailTemplateDetail, this.id).then(
        action(
          response => {
            let data = response.data;
            if (response && response.status === 200 && response.data && !response.data.isError) {
              if (this.isSendTestEmail) {
                data.result.campaignName = campaignStore.emailTemplate.campaignName;
                data.result.htmlTemplate = campaignStore.emailTemplate.htmlTemplate;
                data.result.jsonTemplate = campaignStore.emailTemplate.jsonTemplate;
                data.result.description = campaignStore.emailTemplate.description;
                data.result.category = campaignStore.emailTemplate.category;
                data.result.id = campaignStore.emailTemplate.id;
                this.tempTemplateJsonData = campaignStore.emailTemplate.jsonTemplate;
                this.tempTemplateHtmlData = campaignStore.emailTemplate.htmlTemplate;
                this.isSendTestEmail = false;
              }
              this.setFormData(data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }

            return Promise.resolve(data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getEmailCampaignDetail() {
      return (ApiService.getDetail(ApiUrl.getEmailCampaignDetail, this.id).then(
        action(
          response => {
            let data = response.data;
            if (response && response.status === 200 && response.data && !response.data.isError) {
              if (this.isSendTestEmail) {
                data.result.campaignName = campaignStore.emailTemplate.campaignName;
                data.result.htmlTemplate = campaignStore.emailTemplate.htmlTemplate;
                data.result.jsonTemplate = campaignStore.emailTemplate.jsonTemplate;
                data.result.description = campaignStore.emailTemplate.description;
                data.result.category = campaignStore.emailTemplate.category;
                data.result.id = campaignStore.emailTemplate.id;
                this.tempTemplateJsonData = campaignStore.emailTemplate.jsonTemplate;
                this.tempTemplateHtmlData = campaignStore.emailTemplate.htmlTemplate;
                this.isSendTestEmail = false;
              }
              this.setFormData(data.result);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    deleteEmailTemplate(id) {
      return (ApiService.remove(ApiUrl.deleteEmailTemplate, id).then(
        action(
          response => {
            this.getEmailTemplates()
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    deleteEmailExisting(id) {
      return (ApiService.remove(ApiUrl.deleteEmailCampaign, id).then(
        action(
          response => {
            this.getEmails()
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    submit() {
      const data = {
        name: this.campaignName,
        jsonTemplate: this.jsonTemplate,
        htmlTemplate: this.htmlTemplate,
        description: this.description,
        category: JSON.stringify([...new Set(this.tagCategories.map((item) => {
          return item.value;
        }))]),
      };
      if (!this.isSaveToTemplate) {
        if (this.id && !this.isUseEmailTemplate && !this.isCopyToExisting) {
          return this.update(data).then(response => {
            if (response && !response.isError) {
              this.setOriginalCampaignName(this.campaignName);
            }
            return response;
          });
        } else if (this.id && !this.isUseEmailTemplate && this.isCopyToExisting) {
          return this.createTemplate(data)
        } else {
          return this.create(data).then(response => {
            if (response && !response.isError) {
              this.setOriginalCampaignName(this.campaignName);
            }
            return response;
          });
        }
      } else {
        this.submitTemplate();
      }
    },

    submitTemplate() {
      const data = {
        name: this.campaignName,
        jsonTemplate: this.jsonTemplate,
        htmlTemplate: this.htmlTemplate,
        description: this.description,
        category: JSON.stringify(this.tagCategories.map((item) => {
          return item["value"];
        })),
      };

      if (this.id && !this.isUseEmailTemplate && this.isEditTemplate) {
        return this.updateTemplate(data)
      } else {
        return this.createTemplate(data)
      }
    },

    create(data = {}) {
      return (ApiService.add(ApiUrl.addEmailCampaign, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.id = response.data.result.id;
              this.isFormDirty = false;
              this.isUseEmailTemplate = false;
              window.history.pushState("", "", `/admin/emails/editor/${response.data.result.id}`);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          error => {
            return Promise.reject(error);
          }
        )
      ));
    },

    update(data = {}) {
      const newData = {
        ...data,
        id: this.id,
        forceUpdate: this.forceUpdate
      };

      return (ApiService.edit(ApiUrl.updateEmailCampaign, newData).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.isFormDirty = false;
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          error => {
            return Promise.reject(error);
          }
        )
      ));
    },

    createTemplate(data = {}) {
      const url = this.isSaveToTemplate ? ApiUrl.copyEmailToTemplate : ApiUrl.copyEmailToExisting;
      return (ApiService.add(url, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.isFormDirty = false;
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          error => {
            return Promise.reject(error);
          }
        )
      ));
    },

    updateTemplate(data = {}) {
      const newData = {
        ...data,
        id: this.id
      };

      return (ApiService.edit(ApiUrl.updateEmailTemplate, newData).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.isFormDirty = false;
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          error => {
            return Promise.reject(error);
          }
        )
      ));
    },

    isExistEmailCampaignInAutomation(id) {
      return (ApiService.getDetail(ApiUrl.IsEmailCampaignUsedInAutomation, id).then(
        action(
          response => {
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    toggleShowToRecruiter(id, isShowToRecruiter) {
      const data = {
        id,
        isShowToRecruiter
      };

      return (ApiService.edit(ApiUrl.emailTemplateSetShowToRecruiter, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.getEmailTemplates()
            } else {
              Alert.error(response.data.message || Constants.ALERT_SOMETHING_WENT_WRONG);
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    newSendTestEmail(data = {}) {
      return (ApiService.post(ApiUrl.newSendTestMail, data).then(
        action(
          response => {
            if (response?.status === 200 && response.data && !response.data.isError) {
              Alert.success(Constants.ALERT_SUCCESS_SEND_EMAIL);
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(action(error => Promise.reject(error))));
    },

    newSendTestAlert(data = {}) {
      return (ApiService.post(ApiUrl.sendTestAlert, data).then(
        action(
          response => {
            if (response?.status === 200 && response.data && !response.data.isError) {
              Alert.success(Constants.ALERT_SUCCESS_SEND_EMAIL);
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(action(error => Promise.reject(error))));
    },

    getIsNameInUse(name) {
      return (ApiService.get(ApiUrl.getEmailCampaignIsNameInUse + `?name=${name}`, "", "Failed to get email data").then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              return Promise.resolve(response.data);
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
          }
        )
      ).catch(action(err => Promise.reject(err))));
    },

    getIsTemplateNameInUse(name) {
      return (ApiService.get(ApiUrl.getEmailTemplateIsNameInUse + `?name=${name}`, "", "Failed to get email data").then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              return Promise.resolve(response.data);
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
          }
        )
      ).catch(action(err => Promise.reject(err))));
    },

    postSaveAsNew(id, name) {
      const data = {
        id: id,
        name: name,
      };

      return (ApiService.post(ApiUrl.postEmailCampaignSaveAsNew, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              return Promise.resolve(response.data);
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
          }
        )
      ).catch(action(err => Promise.reject(err))));
    },
  }
);

export default emailStore;

import { observable, action } from "mobx";
import { ApiService, ApiUrl,Constants } from "helpers";
import Alert from 'react-s-alert';

const handleApiResponse = (response, errorMessage) => {
  if (response?.status === 200 && response?.data && !response?.data.isError) {
    return Promise.resolve(response.data);
  }
  Alert.error(errorMessage);
  return Promise.reject();
};

const beePluginStore = observable(
  {
    companyColors: [],

    getCompanyColors: action(function(colorType = null) {
      return ApiService.get(
        ApiUrl.getCompanyColors, 
        "", 
        'Failed to get company colors'
      ).then(action(response => {
        const result = handleApiResponse(response, 'Failed to get company colors');
        if (result) {
          console.log(response.data.result);
          this.companyColors = (response.data.result || []).filter(color => !color.isDeleted);
        }
        return result;
      }));
    }),

    get defaultColors() {
      return this.companyColors.length > 0 
        ? this.companyColors[0].content.split(',')
        : ['#FFFFFF', '#000000'];
    },

    setSelectedColor: action(function(colorType, colors) {
      if (colorType === undefined || !colors) return;
      
      const newColor = typeof colors === 'string' ? colors : colors[0];
      const apiData = {
        colorType: Number(colorType),
        content: newColor
      };

      return this.createCompanyColor(apiData)
        .catch(error => {
          this.getCompanyColors();
          Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
          return Promise.reject(error);
        });
    }),

    createCompanyColor: action(function(data) {
      if (data.colorType === undefined || !data.content) return Promise.reject('Invalid data');
      
      return ApiService.post(ApiUrl.createCompanyColor, data)
        .then(action(response => {
          const result = handleApiResponse(response, Constants.ALERT_SOMETHING_WENT_WRONG);
          if (result) {
            this.getCompanyColors();
          }
          return result;
        }));
    }),
  }
);

export default beePluginStore;

import { FeatureType, CampaignBlastMethod } from "helpers/enum";


export const getActivitySortOrder = (activity) => {
    if (activity.name === "Any") {
      return 0;
    }
  
    // Email activities (excluding Link Click)
    if (activity.type === CampaignBlastMethod.Email && !activity.linkType) {
      const emailOrder = {
        'Email Delivered': 1,
        'Email Opened': 2,
        'Email Invalid': 3,
        'Email Bounced': 4,
        'Email Unsubscribe': 5,
        'Email Link Click': 6
      };
      return emailOrder[activity.name] || 999;
    }
  
    // Email Link Click (separate section)
    if (activity.linkType === FeatureType.EmailLink || 
        (activity.name === "Email Link Click" && activity.linkType)) {
      return 7;
    }
  
    // SMS activities (excluding Link Click)
    if (activity.type === CampaignBlastMethod.Text && !activity.linkType) {
      const smsOrder = {
        'SMS Sent': 8,
        'SMS Delivered': 9,
        'SMS Invalid': 10,
        'SMS Unsubscribe': 11
      };
      return smsOrder[activity.name] || 999;
    }
  
    // SMS Link Click (separate section)
    if (activity.linkType === FeatureType.SMSLink || 
        (activity.name === "SMS Link Clicked" && activity.linkType)) {
      return 12;
    }
  
    // Survey activities
    if (activity.type === CampaignBlastMethod.Survey) {
      const surveyOrder = {
        'Survey Opened': 13,
        'Survey Completed': 14
      };
      return surveyOrder[activity.name] || 999;
    }
  
    // Web activities
    if (activity.name.startsWith("Web Activity")) {
      const webOrder = {
        'Web Activity - Page View': 15,
        'Web Activity - Job View': 16,
        'Web Activity - Application View': 17
      };
      return webOrder[activity.name] || 999;
    }
  
    return 999; // Default sort order for unspecified activities
};

export const getDelayActivitySortOrder = (activity) => {
  if (activity.name === "Any") {
    return 0;
  }

  // Email activities
  if (activity.name.startsWith("Email")) {
    const emailOrder = {
      'Email Delivered': 1,
      'Email Opened': 2,
      'Email Invalid': 3,
      'Email Bounced': 4,
      'Email Link Click': 5
    };
    return emailOrder[activity.name] || 50; // Default for other email activities
  }

  // SMS activities
  if (activity.name.startsWith("SMS")) {
    const smsOrder = {
      'SMS Sent': 100,
      'SMS Delivered': 101,
      'SMS Invalid': 102,
      'SMS Link Clicked': 103
    };
    return smsOrder[activity.name] || 150; // Default for other SMS activities
  }

  // Survey activities
  if (activity.name.startsWith("Survey")) {
    const surveyOrder = {
      'Survey Opened': 200,
      'Survey Completed': 201
    };
    return surveyOrder[activity.name] || 250; // Default for other survey activities
  }

  // Web activities
  if (activity.name.startsWith("Web Activity")) {
    const webOrder = {
      'Web Activity - Page View': 300,
      'Web Activity - Job View': 301,
      'Web Activity - Application View': 302
    };
    return webOrder[activity.name] || 350; // Default for other web activities
  }

  return 999; // Default sort order for any unmatched activities
};